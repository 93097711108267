import * as React from "react";
import styled from "styled-components";
import { graphql, navigate, Link } from "gatsby";
import { IndexPageQuery } from "../../types/graphql-types";
import Layout from "../components/Layout";
import { theme } from "../components/theme";
import MediaQuery from "react-responsive";
import { Meta } from "../components/Meta";
import { Helmet } from "react-helmet";
const removeMd = require("remove-markdown");

interface IndexPageProps {
  data: IndexPageQuery;
}

const Home: React.FC<IndexPageProps> = ({ data }) => {
  return (
    <Layout>
      <Meta />
      <Helmet>
        <title>kame's blog - Top</title>
      </Helmet>

      <Container>
        {data.allContentfulBlogPost.edges.map((item) => {
          return (
            <StyledLink
              to={`blogPost/${item.node.contentful_id}`}
              key={item.node.contentful_id}
            >
              <CardContainer>
                <CardMainContainer>
                  <CardHeaderStyle>
                    <h2>{item.node.title}</h2>
                    <div>
                      <div>
                        <img
                          alt={"avatorImage"}
                          src={`${item.node.refAuthor.avatorImage.file.url}?w=24`}
                        />
                        <div>{item.node.refAuthor.name}</div>
                      </div>

                      <div>
                        <div>{"/"}</div>
                      </div>

                      <div>
                        <div>{item.node.postedAt}</div>
                      </div>
                    </div>

                    <div>
                      {item.node.refCategories.map((item, index) => {
                        return <span key={index}>{item.name}</span>;
                      })}
                    </div>
                  </CardHeaderStyle>
                  <CardMainStyle>
                    {item.node.overView
                      ? item.node.overView
                      : removeMd(item.node.body.body)}
                  </CardMainStyle>
                </CardMainContainer>

                <MediaQuery
                  query={`(min-width: ${theme.ConstBreakPoint.MIN_PC})`}
                >
                  <CardImageContainer>
                    <img
                      alt={"heroImage"}
                      src={`${item.node.heroImage.file.url}?w=200`}
                    />
                  </CardImageContainer>
                </MediaQuery>
              </CardContainer>
            </StyledLink>
          );
        })}
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query IndexPage {
    allContentfulBlogPost {
      edges {
        node {
          contentful_id
          heroImage {
            file {
              url
            }
          }
          title
          overView
          refAuthor {
            avatorImage {
              file {
                url
              }
            }
            name
          }
          refCategories {
            name
          }
          postedAt(fromNow: true)
          body {
            body
          }
        }
      }
    }
  }
`;

export default Home;

const Container = styled.div`
  margin: 0 auto;
  padding: 0 12px;

  width: 100vw;

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    width: ${theme.width.body};
    padding: 0;
  }
`;

const CardContainer = styled.div`
  margin-top: 20px;
  padding: 24px 0;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  border-bottom: solid 1px #eee;
`;

const CardMainContainer = styled.div`
  width: 100%;

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    padding-right: 20px;
    width: calc(100% - 200px);
  }
`;

const CardImageContainer = styled.div`
  width: 200px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const CardHeaderStyle = styled.div`
  // Title
  & > :nth-child(1) {
    margin: 8px 0;

    color: ${theme.colors.black};
  }

  // Info
  & > :nth-child(2) {
    margin: 8px 0;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    & > div {
      margin-right: 16px;
    }

    // avator
    & > :nth-child(1) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      & > :nth-child(1) {
        margin-right: 4px;
      }
    }

    // updateAt
    & > :nth-child(2) {
    }
  }

  // category
  & > :nth-child(3) {
    margin: 8px 0;

    // category Badge
    & > span {
      padding: 3px 6px;
      margin-right: 8px;
      margin-left: 1px;
      font-size: 75%;
      color: ${theme.colors.black};
      background-color: ${theme.colors.grey20};
      border-radius: 6px;
      box-shadow: 0 0 3px #ddd;
      white-space: nowrap;
    }
  }
`;

const CardMainStyle = styled.div`
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  margin: 24px 0 16px;

  color: ${theme.colors.black};
  font-weight: 600;
`;
